import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Header, Icon, Menu, Modal } from "semantic-ui-react";
import { AdminsDropdown, StatusDropdown } from "../../../components";
import { ClientAction } from "../../../redux/actions";


const Info = () => {
    const [form, setForm] = useState({})
    const [errors, setErrors] = useState({})
    const [transferModal, setTransferModal] = useState()
    const [transferBroker, setTransferBroker] = useState()

    const {id} = useParams()

    const archive = async (archived) => {
        const response = await ClientAction.archive({id, archived})
        if (response.ok) {
            getClient()
        }
    }

    const transferClient = async () => {
        const response = await ClientAction.transfer({id, broker: transferBroker})
        if (response.ok){
            getClient()
        }
    }

    const getClient = async () => {
        const response = await ClientAction.get(id)
        if (response.ok) {
            setForm(await response.json())
        }
    }
    
    useEffect(() => {
        getClient()
    }, [id])

    const submit = async () => {
        const response = await ClientAction.update(form)
        if (response.ok){
            getClient()
        }else {
            setErrors(await response.json())
        }
    }
    return (
        <>
            <Form>
                <Form.Field>
                    <Button
                        floated="right"
                        type="button"
                        content={form.archived ? "Разархивировать" : "Архивировать"}
                        icon="trash"
                        color={form.archived ? "yellow" : "red"}
                        onClick={() => archive(!form.archived)}/>
                    <Button
                        floated="right"
                        type="button"
                        content={"Передать клиента"}
                        icon="trash"
                        onClick={() => setTransferModal({})}/>
                </Form.Field>
                <Form.Input
                    label="ФИО"
                    placeholder="Введите фио клиента"
                    value={form.name}
                    error={errors.name}
                    onChange={(e) => setForm({...form, name: e.target.value})}/>
                <Form.Input
                    label="Телефон"
                    placeholder="Введите телефон"
                    value={form.phone}
                    error={errors.phone}
                    onChange={(e) => setForm({...form, phone: e.target.value})}/>
                <Form.Input
                    label="Телефон 2"
                    placeholder="Введите телефон 2"
                    value={form.phone2}
                    error={errors.phone2}
                    onChange={(e) => setForm({...form, phone2: e.target.value})}/>
                <Form.Input
                    label="Телефон 3"
                    placeholder="Введите телефон 3"
                    value={form.phone3}
                    error={errors.phone3}
                    onChange={(e) => setForm({...form, phone3: e.target.value})}/>
                <Form.Input
                    label="Комментарий"
                    placeholder="Введите комментарий"
                    value={form.comment}
                    onChange={(e) => setForm({...form, comment: e.target.value})}/>
                <Form.Field>
                    <label>Статус</label>
                    <StatusDropdown
                        value={form.status}
                        onChange={(status) => setForm({...form, status})}/>
                </Form.Field>
                <Form.Field>
                    <Button
                        icon="save"
                        content="Сохранить"
                        color="green"
                        onClick={submit}/>
                </Form.Field>
            </Form>
            <Modal open={!!transferModal} onClose={() => setTransferModal()}>
                <Modal.Header>
                    Передать клиента
                </Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Field>
                            <label>Брокер</label>
                            <AdminsDropdown
                                role={"all"}
                                onChange={(broker) => setTransferBroker(broker)}
                                value={transferBroker}/>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content="Отменить"
                        onClick={() => setTransferModal()}/>
                    <Button
                        content="Передать"
                        color="green"
                        onClick={transferClient}/>
                </Modal.Actions>
            </Modal>
        </>
    )
}

export default Info