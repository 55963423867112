import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Grid, Header, Menu, Segment } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Clients from './clients'
import ClientsArchive from './archive'

const ClientsIndex = () => {
    const { pathname  } = useLocation()
    return (
        <Grid>
            <Grid.Column width={3}>
                <Menu vertical >
                    <Menu.Item 
                        as={Link} 
                        to="/clients"
                        active={pathname === '/clients'}>Активные</Menu.Item>
                    <Menu.Item
                        as={Link}
                        to="/clients/archive"
                        active={pathname === '/clients/archive'}>Архив</Menu.Item>
                </Menu>
            </Grid.Column>
            <Grid.Column width={13}>
                <Segment>
                    <Routes>
                        <Route path="/*" element={<Clients/>}/>
                        <Route path="/archive" element={<ClientsArchive/>}/>
                    </Routes>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default ClientsIndex
