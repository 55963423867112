import React from "react";
import { Route, Routes } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Register from './register'
import Login from './login'
export default () => {

    return (
        <Container>
            <Routes>
                <Route path="/register" element={<Register/>}></Route>
                <Route path="/login" element={<Login/>}></Route>
            </Routes>
        </Container>
    )
}